$text: #111111;
$primary: #158390;

$black: #3c3c3c;
$almost-black: lighten($black, 5%);

$white: #ffffff;
$almost-white: #e9ddee;

$black-bg: #160e1a;
